.Tipo {
  padding-top: 100px;
}
.Tipo-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Tipo-img {
  max-width: 300px;
  border-radius: 10px;
  margin: 10px;
}

.Tipo-seleccion,
.Tipo-seleccion:visited {
  text-align: center;
  text-decoration: none;
  max-width: 300px;
}
