.Login {
  padding-top: 70px;
}

.Login-form {
  padding-top: 50px;
  margin: 0 auto;
  text-align: center;
  width: 600px;
  max-width: 100%;
}

.Login-form input {
  height: 30px;
  padding: 0 10px;
  text-align: left;
  width: 400px;
  max-width: 80%;
  outline: none;
  border-radius: 3px;
  border: 1px solid gray;
  margin: 5px 0;
}
.Login-form input:focus {
  box-shadow: 0 0 5px #81beff;
  outline: none;
}

.Login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 6rem;
  height: 2rem;
  border: 0;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.Login-button:focus {
  outline: none;
}

.error {
  margin: 0 auto;
  background-color: red;
  text-align: center;
  width: 422px;
}
.Login-link {
  font-weight: bold;
}

.Login-link:hover {
  cursor: pointer;
}

.Login-img {
  margin: 20px;
  width: 200px;
  border-radius: 20px;
}

.Login-modal {
  margin: 0 auto;
  margin-top: 30px;
  border: black solid 1px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  background-color: white;
}

.Login-modal:focus {
  outline: none;
}

.Login-eleccion {
  display: flex;
  justify-content: space-around;
}

.Login-modal2 {
  margin: 0 auto;
  margin-top: 60px;
  padding: 20px;
  border: black solid 1px;
  border-radius: 20px;
  width: 400px;
  height: auto;
  background-color: white;
  text-align: center;
}

.Login-modal2:focus {
  outline: none;
}

.Aspirantes{
  text-align: center;
  margin-bottom: 15px;
}

.Aspirantes-button{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 15rem;
  min-height: 2rem;
  border: 0;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-decoration: none;

}
.Aspirantes-button:focus{
  outline: none;
}


.BAC{
  font-weight: bold;
  text-align: center;
}

.separacion{
  height: 15px;
}