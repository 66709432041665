.Header {
  position: fixed;
  width: 100%;
  background-color: white;
}

.Header-alumno p {
  margin: 0;
}

.Header-alumno a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  padding: 10px;
  max-width: 100px;
}

@media (min-width: 928px) {
  .logo img {
    max-width: 150px;
  }
}
h1 {
  color: rgb(114, 128, 137);
  font-size: 1rem;
}

@media (min-width: 928px) {
  h1 {
    font-size: 1.5rem;
  }
}

.Header-logout {
  cursor: pointer;
}
