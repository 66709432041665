.Formulario {
  padding-top: 70px;
  padding-bottom: 50px;
}

.Formulario h2 {
  text-align: center;
}

@media (min-width: 1024px) {
  .Formulario-flex {
    display: flex;
    justify-content: space-around;
  }

  .Formulario-total {
    width: 400px;
  }
}

.Formulario-total {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 200px;
  background-color: rgb(235, 235, 235);
  border-radius: 20px;
}

.Formulario-concepto {
  padding: 0 50px 0 0;
}

.Formulario-par {
  background-color: lightgray;
  border: none;
}

table {
  border-collapse: collapse;
}

thead {
  text-align: left;
}

.Formulario h4 {
  text-align: center;
  font-size: 50px;
  margin: 10px;
}

.Formulario h4 span {
  color: green;
}

.Formulario-boton {
  background-color: #007bff;
  color: white;
  border: none;
  width: 100%;
  margin-top: 10px;
  height: 70px;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 20px;
  transition: ease 0.2s;
  text-align: center;
}

@media (min-width: 1024px) {
  .Formulario-boton {
    width: 400px;
  }
}

.Formulario-boton:hover {
  transform: scale(1.05);
}

.Formulario-boton:focus {
  outline: none;
}

.Formulario-error {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  height: 40px;
}
