.Opciones {
  max-width: 80%;
  margin: 0 auto;
  padding-top: 100px;
}

.Opciones-titulo {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}

.Opciones-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Opcion {
  margin-bottom: 20px;
  text-align: center;
}

.Opcion img {
  text-align: center;
  width: auto;
  max-width: 300px;
}

@media (max-width: 1024px) {
  .Opcion img {
    text-align: center;
    width: auto;
    max-width: 250px;
  }
}
