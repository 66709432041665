.FormularioExamen {
  padding-top: 100px;
}

@media (min-width: 1024px) {
  .FormularioExamen-flex {
    display: flex;
    justify-content: space-around;
  }

  .FormularioExamen-total {
    width: 400px;
  }
}

.FormularioExamen-form {
  text-align: center;
  width: 100%;
}
@media (min-width: 1024px) {
  .FormularioExamen-form {
    width: 30%;
  }
}

.FormularioExamen-examen {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.FormularioExamen-cursos {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.FormularioExamen h2 {
  text-align: center;
}

.FormularioExamen label {
  font-weight: bold;
}

.FormularioExamen-total {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 200px;
  background-color: rgb(235, 235, 235);
  border-radius: 20px;
}

.FormularioExamen h4 {
  text-align: center;
  font-size: 50px;
  margin: 10px;
}

.FormularioExamen h4 span {
  color: green;
}
